//Template helpers

var _Utilities = {
    smallScreen: function() {
        return screen.width <= 800;
    }
};

(function ($, kendo) {
    "use strict";

    var Portfolio = {

        LayoutViewModel: kendo.observable({
			/**
			 * @param  {eventObject} e
			 * @param  {property} {vartarget=$(e.currentTarget
			 * @param  {array} _siblings=target.siblings(
			 * @param  {string} ;_siblings.removeClass("active"
			 * @param  {string} ;target.addClass("active"
			 * @param  {any} ;}
			 */

            highlightNav: function (item) {
                if (item) {
                    var _siblings = item.siblings();

                    _siblings.removeClass("k-state-active");
                    item.addClass("k-state-active");
                }
            },

            onNavSelect: function (e) {
                var item = $(e.item),
                    _data = item.data();

                if (_data.bgImg) {
                    this.set("backgroundImg", _data.bgImg);
                    this.highlightNav(item);
                }
            },

            detectRoute: function () {
                var hash = location.hash.split('/')[1],
                    anchors = $('ul#mainNav li a'),
                    _self = this;

                anchors.each(function (i) {
                    var ele = $(this);
                    var href = ele.attr('href').split('/')[1];
                    if (href === hash) {
                        _self.highlightNav(ele.parent());
                        return false;
                    }
                });
            },

            menuSource: new kendo.data.DataSource({
                data: [
                    {
                        text: "Home",
                        url: "#/"
                    },
                    {
                        text: "Work",
                        url: "#/work"
                    },
                    {
                        text: "Resume",
                        url: "#/resume"
                    },
                    {
                        text: "Bio",
                        url: "#/bio"
                    }
                ]
            }),

            backgroundImg: "col-md-9 col-sm-12 bio-bg",

            isLoading: false,

            showNotification: function (text, type) {
                function onShow(e) {
                    if (e.sender.getNotifications().length == 1) {
                        var element = e.element.parent(),
                            eWidth = element.width(),
                            eHeight = element.height(),
                            wWidth = $(window).width(),
                            wHeight = $(window).height(),
                            newTop, newLeft;

                        newLeft = Math.floor(wWidth / 2 - eWidth / 2);
                        newTop = Math.floor(wHeight / 2 - eHeight / 2);

                        e.element.parent().css({top: 30, left: newLeft});
                    }
                }

                var notice = $("#notification").kendoNotification({
                    show: onShow,
                    width: 320
                }).data("kendoNotification");

                notice.show(text, type);
            }
        }),

        GalleryViewModel: kendo.observable({
            inProcess: false,

            contentModel: {
                Title: null,
                Type: null,
                Thumbnail: null,
                Image: null,
                Link: null,
                LinkText: null,
                Description: null,
                Role: null
            },

            firstLoad: false,

            webSource: new kendo.data.DataSource({
                transport: {
                    read: {
                        url: "js/web-work.json",
                        dataType: "json"
                    }
                }
            }),

            graphicsSource: new kendo.data.DataSource({
                transport: {
                    read: {
                        url: "js/ghx-work.json",
                        dataType: "json"
                    }
                }
            }),

            setFirstGalleryItem: function () {
                var dataSource = this.webSource,
                    _self = this;
                dataSource.fetch(function () {
                    var item = dataSource.at(0),
                        _model = {
                            Title: item.Title,
                            Type: item.Type,
                            Thumbnail: item.Thumbnail,
                            Image: item.Image,
                            Link: item.Link,
                            LinkText: item.LinkText,
                            Description: item.Description,
                            Role: item.Role
                        };

                    _self.set("contentModel", _model);
                })
            },

            mobileSetContentModel: function(e) {

                this.setContentModel(e);
                if(_Utilities.smallScreen()) this.showDescription(e, true);
                else this.showDescription(e, false);
                
            },

            setContentModel: function (e) {
                var target = $(e.currentTarget),
                    data = target.data(),
                    model = {
                        Title: data.title,
                        Type: data.type,
                        Thumbnail: data.thumbnail,
                        Image: data.image,
                        Link: data.link,
                        LinkText: data.label,
                        Description: data.description,
                        Role: data.jobRole
                    };

                this.set("contentModel", model);

            },

            showDescription: function (e, setWidth) {
                var _modal = $("#descriptionModal").data("kendoWindow");
                if (setWidth) {
                    _modal.bind('open', function () {
                        console.log(_modal.element);
                        _modal.element.parent().css('width', screen.width);
                        _modal.element.css('width', screen.width);
                    });
                    _modal.title(this.get("contentModel.Title")).open();
                }
                 _modal.title(this.get("contentModel.Title")).center().open();
            }
        }),

        BioViewModel: kendo.observable({
            bioSet: false,
            bio: false,
            getBio: function () {
                if (!this.get("bioSet")) {
                    var _self = this;
                    Portfolio.LayoutViewModel.set("isLoading", true);

                    $.get("../inc/bio.html").done(function (data) {
                        _self.set("bio", data);
                        //_self.set("layout.setBackgroundImg", "row bio-bg");
                        _self.set("bioSet", true);
                    }).fail(function () {
                        Portfolio.LayoutViewModel.showNotification("An unexpected error has occurred, please refresh the page", "error");
                    }).always(function () {
                        Portfolio.LayoutViewModel.set("isLoading", false);
                    });
                }
            }
        }),

        ResumeViewModel: kendo.observable({
            resumeSet: false,
            resumeContent: false,
            getResume: function () {
                if (!this.get("resumeSet")) {
                    var _self = this;
                    Portfolio.LayoutViewModel.set("isLoading", true);
                    $.get("../inc/resume.html").done(function (data) {
                        
                        _self.set("resumeContent", data);
                        _self.set("resumeSet", true);
                        // _self.set("layout.setBackgroundImg", "row bio-bg")
                    }).fail(function () {
                        Portfolio.LayoutViewModel.showNotification("An unexpected error has occurred, please refresh the page", "error");
                    }).always(function () {
                        Portfolio.LayoutViewModel.set("isLoading", false);
                    });
                }
            },

            resumeBodySource: new kendo.data.DataSource({
                transport: {
                    read: {
                        url: "js/resume.json",
                        dataType: "json"
                    }
                }
            })

            //TODO: WRITE DOWNLOAD LOGIC
            // downLoadResume: function() {
            // 	
            // }
        }),

        ContactViewModel: kendo.observable({
            contactInfoModel: {
                name: null,
                email: null,
                phone: null,
                message: null,
                topic: null
            },
            submitForm: function (e) {
                e.preventDefault();
                var validator = $(e.target.form).kendoValidator().data("kendoValidator"),
                    _self = this;

                if (validator.validate()) {
                    Portfolio.LayoutViewModel.set("isLoading", true);

                    $.ajax({
                        type: "POST",
                        data: JSON.stringify(_self.contactInfoModel),
                        url: "utils/process.php"
                    }).done(function(data) {
                        Portfolio.LayoutViewModel.showNotification("Thank you for your submission", "success");
                    }).fail(function(error) {
                        Portfolio.LayoutViewModel.showNotification("An unexpected error has occurred", "error");
                    }).always(function() {
                        Portfolio.LayoutViewModel.set("isLoading", false);
                    });
                }
            }
        })
    };

    Portfolio.Views = {
        Layout: new kendo.Layout("mainLayout-tpl", { model: Portfolio.LayoutViewModel}),
        Gallery: new kendo.View("gallery-tpl", { model: Portfolio.GalleryViewModel}),
        Bio: new kendo.View("bio-tpl", { model: Portfolio.BioViewModel}),
        Resume: new kendo.View("resume-tpl", { model: Portfolio.ResumeViewModel}),
        Intro: new kendo.View("intro-tpl"),
        Contact: new kendo.View("contact-tpl", { model: Portfolio.ContactViewModel})
    };

    Portfolio.App = new kendo.Router({
        init: function () {
            Portfolio.Views.Layout.render("#application");
            Portfolio.LayoutViewModel.detectRoute();
        }
    });

    //Routing
    Portfolio.App.route("/", function () {
        Portfolio.Views.Layout.showIn("#content", Portfolio.Views.Intro, "swap");
    });

    Portfolio.App.route("/work", function () {
        Portfolio.Views.Layout.showIn("#content", Portfolio.Views.Gallery, "swap");
    });

    Portfolio.App.route("/bio", function () {
        Portfolio.Views.Layout.showIn("#content", Portfolio.Views.Bio, "swap");

        var bioSet = Portfolio.BioViewModel.bioSet;

        if (!bioSet) {
            Portfolio.BioViewModel.getBio();
        }
    });

    Portfolio.App.route("/resume", function () {
        Portfolio.Views.Layout.showIn("#content", Portfolio.Views.Resume, "swap");

        var resumeSet = Portfolio.ResumeViewModel.resumeSet;

        if (!resumeSet) {
            Portfolio.ResumeViewModel.getResume();
        }
    });

    Portfolio.App.route("/contact", function () {
        Portfolio.Views.Layout.showIn("#content", Portfolio.Views.Contact, "swap");
    });
    //Any other setup work we want on page load
    Portfolio.Init = function () {
        var topNav = $('.navbar-fixed-top'),
            sideNav = $('ul#mainNav');

        function enableScroll() {
            $(window).on('scroll', function () {
                if ($("body").scrollTop() < 200) {
                    topNav.removeClass('scrolling');
                    sideNav.removeClass('scrolling');
                } else {
                    topNav.addClass('scrolling');
                    sideNav.addClass('scrolling');
                }
            });
        }

        function disableScroll() {
            $(window).off('scroll');
        }

        function checkOrientation() {
            if (_Utilities.smallScreen()) {
                disableScroll();
                topNav.addClass('scrolling');
                sideNav.addClass('scrolling');
            } else {
                topNav.removeClass('scrolling');
                sideNav.removeClass('scrolling');
                enableScroll();
            }
        }

        if (mobilecheck()) {
            topNav.addClass('scrolling');

        } else if (mobileAndTabletcheck() && _Utilities.smallScreen()) {
            topNav.addClass('scrolling');
            $(window).resize(checkOrientation);

        } else if(mobileAndTabletcheck() && !_Utilities.smallScreen()) {
            enableScroll();
            $(window).resize(checkOrientation);

        } else {
            enableScroll();
        }

    };

    //start the whole damn thing
    $(function () {
        Portfolio.App.start();

        //TODO:ABSTRACT THIS A BIT MORE
        Portfolio.GalleryViewModel.setFirstGalleryItem();

        Portfolio.Init();
    });


} (jQuery, kendo));